window.KTUtil = require('../template/components/util.js');
window.KTEventHandler = require('../template/components/event-handler.js');
window.KTBlockUI = require('../template/components/blockui.js');
window.KTCookie = require('../template/components/cookie.js');
window.KTDialer = require('../template/components/dialer.js');
window.KTDrawer = require('../template/components/drawer.js');
window.KTFeedback = require('../template/components/feedback.js');
window.KTImageInput = require('../template/components/image-input.js');
window.KTMenu = require('../template/components/menu.js');
window.KTPasswordMeter = require('../template/components/password-meter.js');
window.KTScroll = require('../template/components/scroll.js');
window.KTScrolltop = require('../template/components/scrolltop.js');
window.KTSearch = require('../template/components/search.js');
window.KTStepper = require('../template/components/stepper.js');
window.KTSticky = require('../template/components/sticky.js');
window.KTSwapper = require('../template/components/swapper.js');
window.KTToggle = require('../template/components/toggle.js');

// Layout base js
window.KTApp = require('../template/layout/app.js');
window.KTLayoutAside = require('../template/layout/aside.js');
window.KTLayoutSearch = require('../template/layout/search.js');
window.KTLayoutToolbar = require('../template/layout/toolbar.js');